const zh = {
  translation: {
    'Sign in credentials': '登录凭据',
    'Sign in': '登入',
    'Add Category': '添加类别',
    Title: '标题',
    Description: '描述',
    Add: '加',
    Remove: '去掉',
    'Add/Remove': '添加/删除',
    Success: '成功',
    Danger: '危险',
    Categories: '分类',
    Image: '图片',
    Header: '头',
    Loading: '载入中',
    Error: '错误',
    Edit: '编辑',
    Delete: '删除',
    'Edit Category': '编辑类别',
    Save: '保存',
    'OrderID prefix': '订单ID前缀',
    Order: '订购',
    Email: '电子邮件',
    Password: '密码',
    Enable: '启用',
    Disable: '禁用',
    'Enable/Disable': '启用/禁用',
    Saving: '保存',
    Paypal: '贝宝',
    'Client ID': '客户ID',
    'Client Secret': '客户秘密',
    Sandbox: '砂箱',
    'Publishable Key': '可发布的密钥',
    'Secret Key': '密钥',
    'Delivery Charges': '送货费',
    Price: '价钱',
    'Add Food': '添加食物',
    Category: '类别',
    Select: '选择',
    Variations: '变化',
    Type: '类型',
    'Food Image': '食物形象',
    Foods: '食品',
    Actions: '操作',
    'Edit Food': '编辑食物',
    OrderID: '订单ID',
    Orders: '命令',
    Name: '名称',
    Items: '项目',
    Payment: '付款',
    Status: '状态',
    Review: '评论',
    'Mark as': '标记为',
    Users: '用户',
    Phone: '电话',
    Address: '地址',
    2019: '2019',
    'About Us': '关于我们',
    Blog: '博客',
    Welcome: '欢迎',
    Logout: '登出',
    Dashboard: '仪表板',
    Configuration: '组态',
    Login: '登录',
    Food: '餐饮',
    'Character limit of max length 50': '最大长度50的字符限制',
    'Character limit of max length 15': '最大长度15的字符限制',
    'Character limit of max length 60': '最大长度60的字符限制',
    'Character limit of max length 20': '最大长度20的字符限制',
    'Character limit of max length 140': '最大长度的字符限制140',
    Currency: '货币',
    Delivery: '送货',
    'Currency Code': '货币代码',
    'Delivery Rate': '送货费率',
    'Currency Symbol': '货币符号',
    enterYourDetailsBelow: '在下面输入您的详细信息',
    LogintoEnatega: '登录到Enatega',
    RememberMe: '记住我',
    ForgotYourPassword: '忘记密码？',
    errorWhileSaving: '保存时出现错误，请重试',
    Saved: '已保存',
    Addons: '附加组件',
    AddRemoveAddon: '添加或删除插件',
    MinQuantity: '最小数量',
    MinimumQuantity: '最小数量',
    MaxQuantity: '最大数量',
    MaximumQuantity: '最大数量',
    Options: '选项',
    NewOption: '新选项',
    CategoryUpdatedSuccessfully: '类别成功更新',
    CategoryAddedSuccessfully: '类别添加成功',
    ActionFailedTryAgain: '操作失败，请重试',
    PHCategory: '类别，例如早餐',
    ChooseCurrency: '选择货币',
    ChooseSymbol: '选择符号',
    PHMail: '例如something@email.com',
    EmailName: '电子邮件名称',
    PHEnatega: '例如Enatega',
    PHFood: '例如FOOD',
    ClientSecretKey: '客户端秘钥',
    Stripe: '条纹',
    PublishKey: '发布密钥',
    CouponUpdated: '优惠券已更新',
    CouponAdded: '已添加优惠券',
    Code: '代码',
    PHCode: '例如SALE50的代码',
    PHDiscount: '折扣百分比，即1-99',
    FoodUpdatedSuccessfully: '食品成功更新',
    FoodAddedSuccessfully: '食品成功添加',
    SelectCategory: '选择类别',
    UploadAnImage: '上传图片',
    UniqueTitle: '标题（必须唯一）',
    Discounted: '打折',
    LoadingDots: '加载中...',
    ErrorDots: '错误...',
    NewAddon: '新插件',
    EnategaMultivendor: 'Enatega 多供应商',
    ResetPassword: '重置密码',
    EnategaDashboard: 'Enatega 仪表板',
    UpdateOption: '更新选项',
    AddOption: '添加选项',
    OrderStatusUpdated: '订单状态已更新',
    SpecialInstructions: '特殊说明',
    Charges: '费用',
    Subtotal: '小计',
    DeliveryFee: '运费',
    TaxCharges: '税费',
    Tip: '小费',
    Total: '总计',
    PaymentMethod: '支付方式',
    PaidAmount: '已支付金额',
    Accepted: '已接受',
    Accept: '接受',
    Cancelled: '已取消',
    Cancel: '取消',
    PHReasonIfRejected: '拒绝原因',
    Datetime: '日期时间',
    PasswordChangedSuccessfully: '密码已成功更改',
    ConfirmPassword: '确认密码',
    Reset: '重置',
    RestaurantAdded: '餐厅已添加',
    NetworkError: '网络错误',
    FieldsRequired: '必填字段',
    Username: '用户名',
    RestaurantUsername: '餐厅用户名',
    RestaurantPassword: '餐厅密码',
    RestaurantName: '餐厅名称',
    RestaurantAddress: '餐厅地址',
    DeliveryTime: '送货时间',
    MinOrder: '最低订单金额',
    SalesTax: '销售税',
    RiderUpdatedSuccessfully: '骑手已成功更新',
    RiderAddedSuccessfully: '骑手已成功添加',
    UsernameCannotBeBlank: '用户名不能为空',
    UsernameCannotContainSpaces: '用户名不能包含空格',
    EditRider: '编辑骑手',
    AddRider: '添加骑手',
    Available: '可用',
    PHRiderName: '骑手姓名',
    Number: '号码',
    PhoneNumber: '电话号码',
    RiderZone: '骑手区域',
    SectionUpdatedSuccessfully: '部分已成功更新',
    SectionAddedSuccessfully: '部分已成功添加',
    EditSection: '编辑部分',
    AddSection: '添加部分',
    SectionName: '部分名称',
    Update: '更新',
    GENERAL: '常规',
    MANAGEMENT: '管理',
    RESTAURANT: '餐厅',
    BackToAdmin: '返回管理员',
    Search: '搜索',
    TippingUpdated: '小费已更新',
    EditTipping: '编辑小费',
    AddTipping: '添加小费',
    Tip1: '小费 1',
    Tip2: '小费 2',
    Tip3: '小费 3',
    PHTip1: '小费 1，例如 10',
    PHTip2: '小费 2，例如 20',
    PHTip3: '小费 3，例如 30',
    EditVendor: '编辑供应商',
    AddVendor: '添加供应商',
    PHNumber: '例如 123',
    ZoneUpdatedSuccessfully: '区域已成功更新',
    ZoneAddedSuccessfully: '区域已成功添加',
    SetZoneOnMap: '在地图上设置区域',
    EditZone: '编辑区域',
    AddZone: '添加区域',
    Minimum: '最小值',
    Maximum: '最大值',
    Action: '操作',
    AvailableAfterPurchasing: '此功能将在购买产品后可用',
    CommissionRates: '佣金率',
    PHCommission: '佣金百分比',
    Discount: '折扣',
    Coupons: '优惠券',
    StartDate: '开始日期',
    GraphFilter: '图表筛选',
    EndDate: '结束日期',
    Apply: '应用',
    UpdatingLocationError: '更新位置和边界时发生错误',
    LocationMarkerRequired: '需要位置标记',
    DeliveryAreaRequired: '需要交付区域',
    LocationUpdatedSuccessfully: '位置和边界已成功更新',
    SetLocation: '设置位置',
    DrawDeliveryBounds: '绘制交付边界',
    SetRestaurantLocation: '设置餐厅位置',
    RemoveDeliveryBounds: '删除交付边界',
    RemoveRestaurantLocation: '删除餐厅位置',
    Reject: '拒绝',
    Delivered: '已交付',
    OrderInformation: '订单信息',
    RestaurantCol: '餐厅',
    Rider: '骑手',
    OrderTime: '订单时间',
    Dispatch: '派送',
    PasswordUpdated: '密码已更新',
    ForgotPassword: '忘记密码',
    NotificationSentAuccessfully: '通知发送成功',
    Notifications: '通知',
    Body: '内容',
    Zones: '区域',
    RequestID: '请求 ID',
    Amount: '金额',
    Date: '日期',
    REQUESTED: '请求中',
    TRANSFERRED: '已转账',
    CANCELLEDStatus: '已取消',
    WithdrawRequests: '提款请求',
    TotalRestaurants: '总餐厅',
    Restaurants: '餐厅',
    Vendors: '商家',
    RestaurantUpdatedSuccessfully: '餐厅更新成功',
    UpdateProfile: '更新个人资料',
    PHRestaurantPassword: '餐馆密码',
    PHRestaurantName: '餐馆名称',
    PHRestaurantAddress: '餐馆地址',
    OrderPrefix: '订单前缀',
    Days: '天',
    OpenTimes: '开放时间',
    TimeSavedSuccessfully: '时间保存成功',
    ErrorWhileSavingTime: '保存时间时出错',
    SavingDots: '保存中...',
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    MainPageText: '一个跨平台软件',
    MainPageText1: '一个功能齐全的解决方案，非常适合构建任何餐厅。',
    ViewSite: '查看网站',
    TotalUsers: '总用户',
    TotalVendors: '总商家',
    TotalRiders: '总骑手',
    RestaurantSections: '餐厅部分',
    ImageMenu: '图片菜单',
    Vendor: '商家',
    AddNewRestaurant: '新增餐厅',
    Ratings: '评分',
    StripeDetailsAttached: 'Stripe 详细信息已附加',
    EditStripeDetails: '编辑 Stripe 详细信息',
    SubmitStripeDetails: '提交 Stripe 详细信息',
    'Restaurant Sections': '餐厅部分',
    Riders: '骑手',
    Tipping: '小费',
    Zone: '区域',
    'Commission Rates': '佣金率',
    'Withdraw Requests': '提款请求',
    Home: '首页',
    Profile: '个人资料',
    Option: '选项',
    Timings: '时间',
    Location: '位置',
    'Back to Admin': '返回管理',
    TotalOrders: '总订单',
    TotalSales: '总销售额',
    ClosedAllDay: '全天关闭',
    AddRestaurant: '添加餐厅',
    EditCoupon: '编辑优惠券',
    AddCoupon: '添加优惠券',
    SalesAmount: '销售额',
    OrderCount: '订单数',
    MON: '星期一',
    TUE: '星期二',
    WED: '星期三',
    THU: '星期四',
    FRI: '星期五',
    SAT: '星期六',
    SUN: '星期日',
    Open: '打开',
    ACCEPTED: '已接受',
    PENDING: '待处理',
    PICKED: '已选择',
    ASSIGNED: '已分配',
    DELIVERED: '已交付',
    REJECTED: '已拒绝',
    UploadaLogo: '上传徽标',

    "Status updated to {{status}}": "状态更新为 {{status}}",
    StatusUpdated: '状态已更新！',
    "Failed to update status!": "更新状态失败！",
    UpdateSuccessful: "更新成功！",
    UpdateError: '更新错误',
    "The value Should not be in the negative": "值不应为负数",

    "CostType": "费用类型",
    "PerKM": "每公里",
    "FixedCost": "固定费用"

  }
}
export default zh
